export const environment = {
  production: true,
  apiUrl: 'https://api.cyberpatient.ca',
  epaApiUrl: 'https://epa-api.cyberpatient.ca',
  stripeKey: 'pk_live_O4Cy0VrU2KeCV6HrYVYdhclf00ckJpbm5X',
  mixpanelToken: 'c1ae492ac02178dfd4dd2c0bec711222',
  helpSurveyId: 'fc5ba17614aa5438',
  emailCampaignId: '62a22338d421c55c38b77243',
  debug: false,
  showEpa: false,
  alphaDebug: 0.0,
  v3Url: 'https://learn.cyberpatient.ca',
};
